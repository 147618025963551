<template>
  <div
    class="container my-5"
  >
    <b-form>
      <b-row class="mb-2">
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
          sm="12"
          class="d-flex justify-content-end px-3"
        >
          <div v-if="type === 'edit'">
            <b-button
              class="btn-icon rounded-pill"
              variant="outline-danger"
              @click="handleRemoveItem"
            >
              <feather-icon
                icon="Trash2Icon"
              />
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
          xl="12"
          lg="12"
          md="12"
          sm="12"
          class="d-flex justify-content-center px-3"
        >
          <div
            class="d-flex flex-column justify-content-center align-items-center text-center border rounded px-4"
          >
            <img
              v-if="pieceType.image"
              :src="pieceType.image"
              width="50"
              height="50"
              class="my-2"
            >
            <span
              v-if="type != 'custom'"
              class="mb-1"
            >{{ pieceType.name }}</span>
          </div>
        </b-col>
      </b-row>
      <!-- CUSTOM NAME -->
      <div
        v-if="type === 'custom'"
        class="d-flex flex-column justify-content-center align-items-center my-2"
      >
        <p class="my-2 font-weight-bolder">
          Custom Item Name
        </p>
        <b-row class="justify-content-center">
          <b-col
            cols="12"
            xl="12"
            lg="12"
            md="12"
            sm="12"
          >
            <b-input-group>
              <b-form-input
                v-model="customName"
                placeholder="Name"
              />
            </b-input-group>

          </b-col>
        </b-row>
      </div>
      <!-- PIECE TYPE -->
      <!--      <div-->
      <!--        class="d-flex flex-column justify-content-center align-items-center my-2"-->
      <!--      >-->
      <!--        <p class="my-2 font-weight-bolder">-->
      <!--          Select Piece Type-->
      <!--        </p>-->
      <!--        <b-row class="w-100 justify-content-center">-->
      <!--          <b-col-->
      <!--            cols="12"-->
      <!--            xl="12"-->
      <!--            lg="12"-->
      <!--            md="12"-->
      <!--            sm="12"-->
      <!--          >-->
      <!--            <b-form-group>-->
      <!--              <v-select-->
      <!--                id="piece-types"-->
      <!--                v-model="pieceType"-->
      <!--                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"-->
      <!--                :options="item.sub_categories"-->
      <!--                :clearable="false"-->
      <!--                label="name"-->
      <!--                class="per-page-selector d-inline-block w-100 rounded"-->
      <!--                placeholder="Select Piece Type"-->
      <!--                @input="handlePieceType"-->
      <!--              />-->
      <!--            </b-form-group>-->

      <!--          </b-col>-->
      <!--        </b-row>-->
      <!--      </div>-->
      <!-- SERVICES -->
      <div class="d-flex flex-column justify-content-center align-items-center my-2">
        <p class="my-2 font-weight-bolder">
          Select Attribute
        </p>
        <b-row class="w-100 justify-content-center">
          <b-col
            cols="6"
            xl="6"
            lg="6"
            md="6"
            sm="6"
          >
            <div
              v-for=" attributeSet in attributeSets"
            >
              <!--              <radio-badge v-if="service === '{{attributeSet.id}}'" />-->

              <span
                class="my-2"
                style="float: right;"
              >{{ attributeSet.title }}</span>
              <b-row class="w-100 justify-content-center">
                <b-col
                  v-for=" attribute in attributeSet.attributes"
                  cols="6"
                  xl="4"
                  lg="4"
                  md="4"
                  sm="4"
                >
                  <div
                    :class="{
                      'd-flex flex-column justify-content-center align-items-center text-center border rounded px-2 mb-1 cursor-pointer position-relative': true,
                      'radio-selected': true
                    }"
                    @click="() => handleSelectAttribute(attribute,attributeSet)"
                  >
                    <radio-badge v-if="attribute.selected" />
                    <span class="my-1">{{ attribute.title }}</span>
                  </div>
                </b-col></b-row>
            </div>

          </b-col>
          <!--          <b-col-->
          <!--            cols="12"-->
          <!--            xl="3"-->
          <!--            lg="3"-->
          <!--            md="3"-->
          <!--            sm="12"-->
          <!--          >-->
          <!--            <div-->
          <!--              :class="{-->
          <!--                'd-flex flex-column justify-content-center align-items-center text-center border rounded px-2 mb-1 cursor-pointer position-relative': true,-->
          <!--                'radio-selected': service === 'dry'-->
          <!--              }"-->
          <!--              @click="() => handleSelectService('dry')"-->
          <!--            >-->
          <!--              <radio-badge v-if="service === 'dry'" />-->
          <!--              <img-->
          <!--                src="@/assets/images/icons/dry.png"-->
          <!--                width="32"-->
          <!--                height="32"-->
          <!--                class="mt-1"-->
          <!--              >-->
          <!--              <span class="my-1">Dry</span>-->
          <!--            </div>-->
          <!--          </b-col>-->
          <!--          <b-col-->
          <!--            cols="12"-->
          <!--            xl="3"-->
          <!--            lg="3"-->
          <!--            md="3"-->
          <!--            sm="12"-->
          <!--          >-->
          <!--            <div-->
          <!--              :class="{-->
          <!--                'd-flex flex-column justify-content-center align-items-center text-center border rounded px-2 mb-1 cursor-pointer position-relative': true,-->
          <!--                'radio-selected': service === 'iron'-->
          <!--              }"-->
          <!--              @click="() => handleSelectService('iron')"-->
          <!--            >-->
          <!--              <radio-badge v-if="service === 'iron'" />-->
          <!--              <img-->
          <!--                src="@/assets/images/icons/steam-iron.png"-->
          <!--                width="32"-->
          <!--                height="32"-->
          <!--                class="mt-1"-->
          <!--              >-->
          <!--              <span class="my-1">Iron</span>-->
          <!--            </div>-->
          <!--          </b-col>-->
        </b-row>
      </div>
      <!-- PRICE -->
      <div class="d-flex flex-column justify-content-center align-items-center my-2">
        <p class="my-2 font-weight-bolder">
          Price Per Item
        </p>
        <b-row class="justify-content-center">
          <b-col
            cols="12"
            xl="8"
            lg="8"
            md="8"
            sm="12"
          >
            <b-input-group
              v-if="type === 'custom'"
              append="KWD"
              class="input-group-merge"
            >
              <b-form-input
                v-model="customPrice"
                placeholder="Price"
                @keypress="numbersOnly"
              />
            </b-input-group>
            <b-input-group
              v-else
              append="KWD"
              class="input-group-merge"
            >
              <b-form-input
                placeholder="Price"
                :disabled="true"
                :value="attributePrice"
              />
            </b-input-group>

          </b-col>
        </b-row>
      </div>
      <!-- QUANTITY -->
      <div class="d-flex flex-column justify-content-center align-items-center my-2">
        <p class="my-2 font-weight-bolder">
          Quantity
        </p>
        <b-row class="justify-content-center">
          <b-col
            cols="3"
            xl="3"
            lg="3"
            md="3"
            sm="3"
            class="text-center"
          >
            <b-button
              class="btn-icon radio-selected"
              variant="primary"
              @click="handleQuantityDecrease"
            >
              <feather-icon
                icon="MinusIcon"
              />
            </b-button>
          </b-col>
          <b-col
            cols="4"
            xl="4"
            lg="4"
            md="4"
            sm="4"
          >
            <b-input-group>
              <b-form-input
                v-model="quantity"
                @keypress="numbersOnly"
              />
            </b-input-group>
          </b-col>
          <b-col
            cols="3"
            xl="3"
            lg="3"
            md="3"
            sm="3"
            class="text-center"
          >
            <b-button
              class="btn-icon radio-selected"
              variant="primary"
              @click="handleQuantityIncrease"
            >
              <feather-icon
                icon="PlusIcon"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <!-- NOTES -->
      <div
        v-if="type !== 'custom'"
        class="d-flex flex-column justify-content-center align-items-center my-2"
      >
        <p class="my-2 font-weight-bolder">
          Notes
        </p>
        <b-row class="justify-content-center w-100">
          <b-col
            cols="12"
            xl="8"
            lg="8"
            md="8"
            sm="8"
          >
            <b-form-textarea
              v-model="notes"
              placeholder="Type notes specific for this item"
              rows="3"
              no-resize
            />
          </b-col>
        </b-row>
      </div>
      <!-- ADD BUTTON -->
      <div class="d-flex flex-column justify-content-center align-items-center my-2">
        <b-row class="justify-content-center w-100">
          <b-col
            cols="12"
            xl="8"
            lg="8"
            md="8"
            sm="8"
          >
            <b-button
              variant="primary"
              class="w-100"
              :disabled="!(Object.keys(pieceType).length > 0 && service && (quantity && !isNaN(quantity)) && (type === 'custom' ? customName && customPrice : 1))"
              @click="handleAddItem"
            >
              {{ type == 'edit' ? 'Edit' : 'Add' }} Item
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-form>
  </div>
</template>
<script>
import store from '@/store'
import { onMounted, ref } from '@vue/composition-api'
import {
  BForm,
  BFormInput,
  BInputGroup,
  // BFormGroup,
  BFormTextarea,
  BButton,
  BRow,
  BCol,
  VBToggle,
} from 'bootstrap-vue'

// import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  integer,
  min,
  required,
} from '@validations'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
// import vSelect from 'vue-select'
import _ from 'lodash'
import useOrders from '../useOrders'
import RadioBadge from '../../RadioBadge.vue'

export default {
  components: {
    // ValidationProvider,
    // ValidationObserver,
    BForm,
    BFormInput,
    BInputGroup,
    // BFormGroup,
    BFormTextarea,
    BButton,
    BRow,
    BCol,
    RadioBadge,
    // vSelect,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  props: {
    item: {
      type: Object,
      default: () => (
        {
          image: 'addTag.png',
        }
      ),
    },
    pieceTypeProp: {
      type: Object,
      default: () => ({}),
    },
    type: {
      type: String,
      default: '',
    },
  },
  setup(props, context) {
    const toast = useToast()
    // console.log(props.item)
    const pieceType = ref(props.item)
    // const pieceType = props.type === 'edit' ? ref(props.item.piece_type) : ref(props.item)
    const customName = ref('')
    const customPrice = ref('')
    const service = ref('')
    const quantity = ref(1)
    const notes = ref('')
    const attributeSets = ref([])
    const variations = ref([])
    const selectedAttributes = ref([])
    const attributes = ref([])
    const attributePrice = ref(0)
    const attributeNames = ref('')
    // const washIronPrice = ref(0)
    // const ironPrice = ref(0)
    // const dryPrice = ref(0)
    const {
      // eslint-disable-next-line no-unused-vars
      fetchProductAtrributes,
      fetchVariations,
    } = useOrders()
    onMounted(() => {
      attributePrice.value = pieceType.value.price
      attributes.value = pieceType.value.attributes
      // eslint-disable-next-line no-undef
      attributeNames.value = ''
      console.log(attributes)
      if (props.type === 'edit') {
        // pieceType.value = props.item.piece_type
        service.value = props.item.selected_service
        quantity.value = props.item.quantity
        notes.value = props.item.notes
      } else {
        // dryCleanPrice.value = pieceType.value.price_dry_clean + props.item.price
        // washIronPrice.value = pieceType.value.price_wash_iron + props.item.price
        // ironPrice.value = pieceType.value.price_iron + props.item.price
        // dryPrice.value = pieceType.value.price_dry + props.item.price
        // pieceType.value = props.pieceTypeProp
        // // eslint-disable-next-line prefer-destructuring, no-lonely-if
        // if (props.item.sub_categories.length > 0) {
        //   // eslint-disable-next-line prefer-destructuring
        //   pieceType.value = props.item.sub_categories[0]
        // }

        service.value = 'wash_iron'
      }
      // eslint-disable-next-line no-use-before-define
      fetchProductAtrributes(pieceType.value.id).then(res => {
        // eslint-disable-next-line guard-for-in
        for (const set in res) {
          // eslint-disable-next-line no-return-assign,no-param-reassign
          res[set].attributes.forEach(element => element.selected = false)
        }
        attributeSets.value = res
      })
      fetchVariations(pieceType.value.id).then(res => {
        variations.value = res
      })
    })

    // eslint-disable-next-line consistent-return
    const numbersOnly = evt => {
      // eslint-disable-next-line no-param-reassign
      evt = (evt) || window.event
      const charCode = (evt.which) ? evt.which : evt.keyCode
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault()
      } else {
        return true
      }
    }
    const handleSelectAttribute = (selectedAttribute, selectedAttributeSet) => {
      // eslint-disable-next-line no-return-assign,no-param-reassign
      selectedAttributeSet.attributes.forEach(element => element.selected = false)
      // eslint-disable-next-line no-param-reassign
      selectedAttributeSet.attributes.find(x => x.id === selectedAttribute.id).selected = true

      // eslint-disable-next-line no-shadow
      const selectedAttributes = []
      // eslint-disable-next-line no-unused-vars
      let names = ''
      // let price = 0
      // eslint-disable-next-line guard-for-in,no-restricted-syntax
      for (const set in attributeSets.value) {
        // console.log(attributeSets.value[set].attributes)
        selectedAttributes.push(attributeSets.value[set].attributes.find(x => x.selected === true))
      }
      attributes.value = selectedAttributes
      //   selectedAttributes.push(attributeSets.forEach(attributes.find(x => x.selected === true).id))
      console.log(selectedAttributes)
      attributePrice.value = pieceType.value.price
      selectedAttributes.forEach(attribute => {
        if (attribute) {
          names += `${attribute.title} `
          attributePrice.value += attribute.price
        }
      })
      attributeNames.value = names
      // attributePrice.value += price
      // console.log( variations.value)
    }
    const handlePieceType = selectedType => {
      pieceType.value = selectedType
    }

    const handleQuantityIncrease = () => {
      quantity.value = +quantity.value + 1
    }

    const handleQuantityDecrease = () => {
      if (quantity.value > 1) {
        quantity.value = +quantity.value - 1
      }
    }

    const handleSidebarClose = () => {
      quantity.value = 1
      service.value = ''
      notes.value = ''
      context.root.$emit('bv::toggle::collapse', 'item-sidebar')
    }

    const handleAddItem = () => {
      const cartItem = { ...props.item }
      // cartItem.piece_type = pieceType.value
      cartItem.quantity = quantity.value
      cartItem.attributes = attributes.value
      cartItem.notes = notes.value
      cartItem.type = 'normal'
      if (props.type === 'custom') {
        // const customPriceKey = `price_${service.value}`
        cartItem.name = customName.value
        // cartItem[customPriceKey] = +customPrice.value
        cartItem.type = 'custom'
      }
      // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line no-unused-vars,no-restricted-syntax,guard-for-in
      cartItem.attributeNames = attributeNames.value
      console.log(cartItem)
      // cartItem.item_price = cartItem.parent.price + +cartItem[`price_${service.value}`]
      cartItem.item_price = attributePrice.value
      const cartItems = [...store.getters['cart/getItems']]
      const duplicateItem = cartItems.filter(item => _.isEqual(_.omit(item, ['quantity', 'total_price', 'item_price', 'notes', 'cart_id']), _.omit(cartItem, ['quantity', 'total_price', 'item_price', 'notes', 'cart_id'])))[0]

      if (props.type === 'edit') {
        store.commit('cart/REMOVE_FROM_CART', props.item)
      }

      if (duplicateItem === undefined) {
        // cartItem.cart_id = store.getters['cart/getItems'].length + 1
        cartItem.cart_id = `${cartItem.id}-${cartItem.selected_service}`
      } else {
        cartItem.cart_id = duplicateItem.cart_id
        if (props.type !== 'edit') {
          cartItem.quantity = quantity.value + duplicateItem.quantity
        }
      }
      cartItem.total_price = cartItem.item_price * cartItem.quantity
      // cartItem.cart_id = `${cartItem.id}-${cartItem.piece_type.id}-${cartItem.selected_service}`
      store.commit('cart/ADD_TO_CART', cartItem)

      handleSidebarClose()

      toast({
        component: ToastificationContent,
        props: {
          title: `Success! ${cartItem.name} has been successfully ${props.type === 'edit' ? 'edited' : 'added to cart'}.`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      },
      {
        position: 'top-center',
      })
    }

    const handleRemoveItem = () => {
      store.commit('cart/REMOVE_FROM_CART', props.item)
      handleSidebarClose()

      toast({
        component: ToastificationContent,
        props: {
          title: `Success! ${props.item.name} has been successfully removed from cart.`,
          icon: 'CheckIcon',
          variant: 'success',
        },
      },
      {
        position: 'top-center',
      })
    }

    return {
      customName,
      customPrice,
      pieceType,
      service,
      quantity,
      notes,
      handlePieceType,
      handleSelectAttribute,
      handleQuantityIncrease,
      handleQuantityDecrease,
      handleAddItem,
      handleSidebarClose,
      handleRemoveItem,
      integer,
      min,
      required,
      numbersOnly,
      attributeSets,
      variations,
      selectedAttributes,
      attributePrice,
      attributeNames,
      // dryCleanPrice,
      // washIronPrice,
      // ironPrice,
      // dryPrice,
    }
  },
}
</script>
