import { ref, watch, computed } from '@vue/composition-api'
// import store from '@/store'
import axios from 'axios'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useOrders() {
  // Use toast
  const toast = useToast()

  const refOrdersTable = ref(null)

  // Table Handlers
  const tableColumns = [
    // { key: 'id', label: '#', sortable: true },
    { key: 'id', sortable: false },
    { key: 'invoice_id', sortable: false },
    { key: 'user', sortable: false },
    // { key: 'type', sortable: false },
    { key: 'created_at', sortable: false },
    // { key: 'initial_status', sortable: false },
    { key: 'status', sortable: false },
    { key: 'payment', sortable: false },
    { key: 'details', label: '' },
  ]
  const perPage = ref(15)
  const totalOrders = ref(0)
  const currentPage = ref(1)
  const lastPage = ref(1)
  const perPageOptions = [15, 30, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)
  const isCurrentFilter = ref('')
  const statusFilter = ref('')
  const paymentStatusFilter = ref('')
  const userFilter = ref('')
  const pickupDriverFilter = ref('')
  const deliveryDriverFilter = ref('')

  const dataMeta = computed(() => {
    const localItemsCount = refOrdersTable.value ? refOrdersTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalOrders.value,
    }
  })

  const refetchData = () => {
    refOrdersTable.value.refresh()
  }

  watch([currentPage, isCurrentFilter, statusFilter, paymentStatusFilter, perPage, searchQuery, userFilter, pickupDriverFilter, deliveryDriverFilter], () => {
    refetchData()
  })

  const fetchOrders = (ctx, callback) => {
    let url = `${process.env.VUE_APP_BASE_API_URL}/pos/orders?paginate&page=${currentPage.value}&perPage=${perPage.value}`

    if (isCurrentFilter.value !== '' && isCurrentFilter.value !== null) {
      url += `&is_current=${isCurrentFilter.value}`
    }

    if (statusFilter.value) {
      url += `&status=${statusFilter.value}`
    }

    if (paymentStatusFilter.value) {
      url += `&payment_status=${paymentStatusFilter.value}`
    }

    if (userFilter.value !== '' && userFilter.value !== null) {
      url += `&user_id=${userFilter.value}`
    }

    if (pickupDriverFilter.value !== '' && pickupDriverFilter.value !== null) {
      url += `&pickup_driver_id=${pickupDriverFilter.value}`
    }

    if (deliveryDriverFilter.value !== '' && deliveryDriverFilter.value !== null) {
      url += `&delivery_driver_id=${deliveryDriverFilter.value}`
    }
    axios
      .get(url, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
      .then(res => {
        const orders = res.data.data.items
        totalOrders.value = res.data.data.total
        currentPage.value = res.data.data.current_page
        lastPage.value = res.data.data.last_page

        callback(orders)
      }).catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching orders list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchItems = () => (
      axios.post(`${process.env.VUE_APP_BASE_API_URL}/getAllProductsPos`)
          .then(res => res.data.data.items)
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching items list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
  )
  const fetchVariations = productId => (
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/product/variations/${productId}`,{
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
          .then(res => res.data.data.products)
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching items list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
  )

  const fetchCategories = () => (
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/categories?type=2`)
          .then(res => res.data.data.items)
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching categories list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
  )

  const fetchSubCategories = category => (
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/categories/${category}`)
          .then(res => res.data.data.category.sub_categories)
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching categories list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
  )
  const fetchProductAtrributes = productId => (
      axios.get(`${process.env.VUE_APP_BASE_API_URL}/product/attributeSets/${productId}`, {
        headers: {
          Authorization: localStorage.getItem('token'),
        },
      })
          .then(res => res.data.data.attributeSets)
          .catch(() => {
            toast({
              component: ToastificationContent,
              props: {
                title: 'Error fetching categories list',
                icon: 'AlertTriangleIcon',
                variant: 'danger',
              },
            })
          })
  )

  const fetchCustomers = () => (
    axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/users`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    })
      .then(res => res.data.data.items)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching customers list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  )

  // const fetchExpressConfig = () => (
  //   axios.get(`${process.env.VUE_APP_BASE_API_URL}/express_service`, {
  //     headers: {
  //       Authorization: localStorage.getItem('token'),
  //     },
  //   })
  //     .then(res => res.data.data)
  //     .catch(() => {
  //       toast({
  //         component: ToastificationContent,
  //         props: {
  //           title: 'Error fetching Express Config list',
  //           icon: 'AlertTriangleIcon',
  //           variant: 'danger',
  //         },
  //       })
  //     })
  // )

  // const fetchWorkingDays = () => (
  //   axios.get(`${process.env.VUE_APP_BASE_API_URL}/workingHours`, {
  //     headers: {
  //       Authorization: localStorage.getItem('token'),
  //     },
  //   })
  //     .then(res => res.data.data.working_days)
  //     .catch(() => {
  //       toast({
  //         component: ToastificationContent,
  //         props: {
  //           title: 'Error fetching working days list',
  //           icon: 'AlertTriangleIcon',
  //           variant: 'danger',
  //         },
  //       })
  //     })
  // )

  const fetchOrder = id => (
    axios.get(`${process.env.VUE_APP_BASE_API_URL}/pos/orders/${id}`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    })
      .then(res => res.data.data.order)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching order',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  )

  const fetchUserAddresses = userId => (
    axios.get(`${process.env.VUE_APP_BASE_API_URL}/user-address/${userId}`, {
      headers: {
        Authorization: localStorage.getItem('token'),
      },
    })
      .then(res => res.data.data.items)
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching user Addresses list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchOrders,
    fetchOrder,
    tableColumns,
    perPage,
    currentPage,
    lastPage,
    totalOrders,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refOrdersTable,
    isCurrentFilter,
    statusFilter,
    paymentStatusFilter,
    userFilter,
    pickupDriverFilter,
    deliveryDriverFilter,
    refetchData,
    fetchCategories,
    fetchSubCategories,
    fetchProductAtrributes,
    fetchVariations,
    fetchItems,
    fetchCustomers,
    // fetchWorkingDays,
    fetchUserAddresses,
    // fetchExpressConfig,
  }
}
